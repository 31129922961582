<template>
  <div></div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {ACCEPT_PLAN} from '../../store/modules/shop/types'
import {requestStatus} from '../../services/index'

export default {
  name: "tariff-component",
  computed: {
    ...mapState({
      status: state => state.shop.status
    })
  },
  methods: {
    ...mapActions({
      acceptedPlan: `shop/${ACCEPT_PLAN}`,
    })
  },
  watch: {
    status(newValue) {
      if (newValue.accept_plan && newValue.accept_plan !== requestStatus.loading) {
        this.$router.push({name: 'tariffs'})
      }
    },
  },
  created: function () {
    this.acceptedPlan(this.$route.query)
  }
}
</script>

<style scoped>

</style>